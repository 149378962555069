import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`-Max Unbroken Double Unders, 2 attempts (scale to single unders)`}</p>
    <p>{`-Max Distance Handstand Walk, 3 attempts (scale to HS Hold)`}</p>
    <p>{`-30 Muscle Ups for time (Ring or Bar, scale to 30 Ring or Bar Dips)`}</p>
    <p>{`-100 Bar Facing Burpees for time.`}</p>
    <p>{`-500M Ski Erg for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      